<template>
  <div class="hello">
    <div class="container mt-5">
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">High</label>
    <input type="number" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" v-model="high">
  </div>
  <div class="mb-3">
  {{((parseFloat(high)-0.027))}}
  </div>
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">Price : &nbsp;&nbsp;&nbsp;{{((parseFloat(high)-0.027)+0.09)}}  </label>
  </div>
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">SL : &nbsp;&nbsp;&nbsp;{{((parseFloat(high)-0.027)+2.91)}}  </label>
  </div>
<hr>
    
    </div>

    <div class="container mt-5">
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">Low</label>
    <input type="number" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" v-model="low">
  </div>
  <div class="mb-3">
  {{((parseFloat(low)+0.027))}}
  </div>
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">Price : &nbsp;&nbsp;&nbsp;{{((parseFloat(low)+0.027)-0.09)}}  </label>
  </div>
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">SL : &nbsp;&nbsp;&nbsp;{{((parseFloat(low)+0.027)-2.91)}}  </label>
  </div>
<hr>
    
    </div>
  </div>

  
</template>

<script>
export default {
  data() {
    return {
      high:0,
      low:0
    };
  },
  methods: {
  },

  mounted() {  
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
